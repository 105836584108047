<template>
  <span>
    <b-icon-person-circle v-if="computer.win_user.toLowerCase() === 'alfa'" class="mr-2" :variant="variantWarning"
                          :id="'tt-winuser-' + computer.id"/>
    <b-icon-wrench v-else-if="computer.win_user.toLowerCase() === 'alfa-support'" class="mr-2"
                   :variant="variantWarning" :id="'tt-winuser-' + computer.id"/>
    <b-icon-star-half v-else-if="computer.win_user.toLowerCase() === 'alfa-admin'" class="mr-2"
                      :variant="variantWarning" :id="'tt-winuser-' + computer.id"/>
    <b-icon-star-fill v-else-if="computer.win_user.toLowerCase() === 'admin'" class="mr-2" :variant="variantWarning"
                      :id="'tt-winuser-' + computer.id"/>
    <b-icon-question-circle v-else class="mr-2" :variant="variantWarning" :id="'tt-winuser-' + computer.id"/>
    <b-tooltip :target="'tt-winuser-' + computer.id" triggers="hover">{{ computer.win_user }}</b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "WinUser",
  props: ['computer'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
