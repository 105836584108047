<template>
  <div v-if="computer.warnings">
    <span v-if="computer.warnings.includes('imaging')">
      <b-icon-hdd-fill variant="primary" :id="'tt-icon-image-' + computer.id"/>
      <b-tooltip :target="'tt-icon-image-' + computer.id" triggers="hover">Der Computer ist im Imaging</b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('serialNumber')">
      <b-icon-upc :variant="variantWarning" :id="'tt-icon-serialNumber-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-serialNumber-' + computer.id" triggers="hover">Der Computer hat keine Seriennummer eingetragen</b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('serviceVersion')">
      <b-icon-gear-wide-connected :variant="variantWarning" :id="'tt-icon-serviceVersion-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-serviceVersion-' + computer.id" triggers="hover">Der Computer verwendet eine ältere Version des Windows-Dienstes</b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('wlan')">
      <b-icon-wifi :variant="variantWarning" :id="'tt-icon-wlan-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-wlan-' + computer.id" triggers="hover">Der Computer ist mit WLAN verbunden</b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('webcam')">
      <b-icon-camera-video-off-fill :variant="variantWarning" :id="'tt-icon-webcam-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-webcam-' + computer.id" triggers="hover">Der Computer hat keine Kamera erkannt oder angeschlossen</b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('uptime')">
      <b-icon-power :variant="variantWarning" :id="'tt-icon-uptime-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-uptime-' + computer.id" triggers="hover">Der Computer läuft bereits mehr als 48 Stunden
      </b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('reboot')">
      <b-icon-plug-fill :variant="variantWarning" :id="'tt-icon-reboot-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-reboot-' + computer.id" triggers="hover">
        Der Computer wurde seit dem letzten Kursstart nicht gestartet
      </b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('battery')">
      <b-icon-battery-half :variant="variantWarning" :id="'tt-icon-battery-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-battery-' + computer.id" triggers="hover">Der Computer ist im Akkubetrieb</b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('customerDiff')">
      <b-icon-people-fill :variant="variantDanger" :id="'tt-icon-customerDiff-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-customerDiff-' + computer.id" triggers="hover">Kunde und RPC-Kunde stimmen nicht überein
      </b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('redundant')">
      <b-icon-files :variant="variantDanger" :id="'tt-icon-redundant-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-redundant-' + computer.id" triggers="hover">Der Computer hat einen doppelten Eintrag oder die Hardware hat sich verändert
      </b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('process')">
      <b-icon-shield-fill-exclamation :variant="variantDanger" :id="'tt-icon-process-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-process-' + computer.id" triggers="hover">Der Computer hat ausstehende Prozessfunde
      </b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('virus')">
      <b-icon-bug-fill :variant="variantDanger" :id="'tt-icon-virus-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-virus-' + computer.id" triggers="hover">Der Computer hat ausstehende Virenfunde
      </b-tooltip>
    </span>
    <span v-if="computer.warnings.includes('license')">
      <b-icon-tags-fill :variant="variantDanger" :id="'tt-icon-license-' + computer.id" class="ml-1"/>
      <b-tooltip :target="'tt-icon-license-' + computer.id" triggers="hover">Der Computer hat ausstehende Lizenzprobleme
      </b-tooltip>
    </span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ComputerWarnings",
  props: ['computer'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
